<template>
  <div>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%"
      >
        <el-table-column
            prop="text"
            label="项目"
            width="110"
        >
        </el-table-column>
        <el-table-column
            prop="value"
            label="内容"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {getBulk8Details} from '@/api/eChnenergyTask'

export default {
  name: 'Bulk4',
  data() {
    return {
      tableData: undefined
    }
  },
  mounted() {
    document.title = '招标信息池-国家能源'
    getBulk8Details({id: this.$route.params.id}).then(res => {
      console.log(res)
      this.tableData = [
        {text: '询价单号', value: res[0].enquiryNumber},
        {text: '报价截止时间', value: res[0].offerAbortTime},
        {text: '报价开始时间', value: res[0].offerStartTime},
        {text: '装运方式', value: res[0].shipmentMode},
        {text: '起运港', value: res[0].sourePort},
        {text: '目的港', value: res[0].targetPort},
      ]
    })
  },
  methods: {}
}
</script>

<style scoped>

</style>
